.profileContainer {
    display: flex;
    /* height: 85vh; */
    height: calc(95vh - 90px);
    justify-content: space-evenly;
    align-items: flex-start;
    padding-top: 120px;
    max-width: 1400px;
    margin: auto;
}

.divisionLine {
    height: 80%;
    width: 2px;
    background-color: #E1E2E1;
}

/* .settingsContainer{
    padding-top: 80px;
} */
.settingsContainer div{
    margin: 5px 0;
}

.SendFeedbackContainer {
    /* height: calc(90vh - 50px); */
    /* background-color: #FDFEF6; */
}

.SendFeedback {
    /* width: 767px; */
    margin: auto;
    /* padding-top: 10vh; */
}

.SendFeedbackTitle {
    font-size: 24px;
    /* margin-bottom: 10vh; */
}

.StarsRaiting {
    /* margin-bottom: 5vh; */
}

.sendButton {
    display: flex;
    justify-content: center;
    margin-top: 3vh;
}

.EditPhotoModal{
    background-color: rgb(182, 106, 0);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    top: 32px;
    right: -73px;
    border: none;
    cursor: pointer;
    z-index: 1;
} 

@media screen and (max-width: 991px) {
    .profileContainer {
        display: block;
        height: 85vh;
        padding: 0vh 10vw 0 10vw;
        position: relative;
        top: 10vh;
    }

    .divisionLine {
        height: 2px;
        width: 80%;
        background-color: #E1E2E1;
        margin: 1.5vh 0;
    }
    .settingsContainer div{
        margin: 1px 0;
    }
    .settingsContainer{
        padding-top: 0;
        padding-bottom: 50px;
    }

}

@media screen and (max-width: 476px) {
    .SendFeedbackContainer{
        /* height: s100vh; */
    }
    .SendFeedback {
        width: 80vw;
    }
    .SendFeedbackTitle {
        font-size: 26px;
        margin-bottom: 5vh;
        padding-top: 5vh;
    }
    .sendButton {
        display: flex;
        justify-content: center;
        margin-top: 3vh;
    }
    
}
