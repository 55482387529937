.FilterByLetter {
    font-family: 'Work Sans';
}

.termBox{
    padding: 1rem 0.5rem;
    border-bottom: 1px solid #E1E2E1;
    margin-right: 15px;
  }

.container {
    height: 100vh;
    overflow-y: hidden;
}

.dictionary {
    /* height: 85vh; */
    height: calc(95vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    position: relative;
}


.termsContainer {
    padding-right: 15px;
    width: 50%;
}

.MeaningContainer {
    background: #E0F2F0;
    height: calc(85vh - 30px);
    width: 50%;
    
}

.meaning {
    background: #E0F2F0;
    height: calc(85vh);
}

.searchParams {
    height: 11vh;
}

.searchParamsInfo {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.searchParamsInfo h3 {
    font-size: 2rem;
}

.termSkeleton {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(74vh - 30px);
}

.meaningSkeleton {
    padding: 25px;
    height: 100%;
}

.meaningSkeletonTitle {
    text-align: center;
}

.meaningSkeletonText {
    padding: 10px;
}

.dictionaryContainer {
    width: 100vw;
    display: flex;
    padding: 15px;
    height: 100%;
}

.filterButton{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    width: auto;
    height: 30px;
    width: 30px;
    margin: 5px;
}

.filterButton:nth-child(4){
    white-space: nowrap;
    width: unset;
    padding: 5px;
    font-size: small;
}

.filterButton:nth-child(3){
    border-radius: 50%;
}

.filterButton:nth-child(2){
    border-radius: 50%;
}

.filterButton:nth-child(1){
    border-radius: 50%;
}


.filterByLetterMain{
    display: flex; 
    background-color: #F5F5F6; 
    width: 330px;
    padding: 5px 10px;
    border-radius: 10px; 
    justify-content: space-between;
    align-items: center; 
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%)
}

.FilterByLetterButtonsContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.moreLetters {
    display: flex;
    align-items: center;
    background-color: #FDFDFD;
    padding: 0 10px;
    border-radius: 10px;
}


.radioSelectLettersContainer {
    display: block;
    position: absolute;
    z-index: 3;
    background-color: rgba(246,249,229,0.95);
    padding: 20px 40px;
}

.radioSelectLettersTitle {
    color: #004F3D;
}

.radioSelectLetters {
    display: flex;
}

.radioSelectLettersColumn {
    margin: 5px 10px;
}



@media screen and (min-width: 1200px) {
    .dictionaryContainer {
        max-width: 1400px;
    }
}
/* 
@media screen and (min-width: 992px) {
    .dictionaryContainer {
        max-width: 960px;
    }
} */

/* @media screen and (min-width: 576px) {
    .dictionaryContainer {
        max-width: 540px;
    }
} */

@media screen and (max-width: 600px){
    .container {
        height: 100%;
       
    }
    .filterByLetterMain{
        display: flex; 
        background-color: #F5F5F6; 
        width: 300px;
        padding: 5px 10px;
        border-radius: 10px; 
        justify-content: space-between;
        align-items: center; 
        box-shadow: 0px 0px 4px rgb(0 0 0 / 25%)
    }

    .termsContainer {
        padding-right: 0px;
        width: 100%;
    }
    .MeaningContainer {
        display: none ;
        width: 100%;
        margin-top: 0px;
        height: calc(100vh - 185px - env(safe-area-inset-top));
        position: relative;
        top: 15px;

    }

    

    .dictionaryContainer {
        padding: 0 1.5rem;
        top: 0;
        position: absolute;
        /* height: unset; */
        /* margin-top: calc(80px + env(safe-area-inset-top))  ; */
        height: calc(100% - 80px - env(safe-area-inset-top));
    }

    .dictionary {
        height: calc(100vh - 70px);
        /* position: relative;
        top: 0; */
        margin-top: 70px;
    }

    

    .searchLanguage {
        margin-bottom: 1px;
        font-size: 10px;
    }

    .searchParams {
        height: 10vh;
        display: grid;
        align-content: center;
    }

    .numberOfTerms {
        display: none;
    }

    .filterButton{
        height: 20px;
        width: 20px;
    }
    .filterByLetterMain{
        height: 30px;
    }
    
    .moreLetters {
        display: flex;
        align-items: center;
        background-color: #FDFDFD;
        padding: 0 10px;
        border-radius: 10px;
        height: 20px;
    }
    .searchParamsInfo h3 {
        font-size:1.1rem;
    }
    
}


@media screen and (max-height: 900px){

    .filterByLetterMain{
        height: 30px;
    }

    .moreLetters {
        display: flex;
        align-items: center;
        background-color: #FDFDFD;
        padding: 0 10px;
        border-radius: 10px;
        height: 20px;
    }
    .filterButton{
        height: 20px;
        width: 20px;
    }

    .searchParamsInfo h3 {
        font-size:1.1rem;
    }

}

@media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    { 

        .container {
            height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)) ;
            overflow-y: hidden;
        }

        .dictionary {
            height: calc(100vh - 70px);
            position: relative;
            /* top: calc(70px ) ; */
            overflow-y: hidden;
        }

        .dictionaryContainer {
            padding: 0 1.5rem;
            top: calc(0px );
            position: absolute;
            /* height: unset; */
            /* margin-top: env(safe-area-inset-top, 70px); */
        }
    }

