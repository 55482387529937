.CarouselItem {
    width: 100%;
    user-select: none;
    pointer-events: none;
    display: flex;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
}

.CarouselItemContent {
    width: 300px;
    height: 375px;
    background-color: #FDFDFD;
    padding: 15px;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
    border-radius: 15px;
}

.CarouselItemContent img {
    display: block;
    margin: auto;
    padding: 3%;
    border-radius: 50%;
    object-fit: cover;
    width: 96px;
    height: 96px;
}

.CarouselItemContentTitle {
    text-align: center;
    color: #068B77;
}

.UserFeedback {
    position: relative;   
    z-index: 2;
    height: 627.2px;
}

.UserFeedback h3 {
    text-align: center;
    color:#004F3D;
    position: absolute;
    top: 3%;
    width: 100%;
    z-index: 2;
}

.ContainerCarousel {
    display: flex;
    align-items: center;
    overflow-y: hidden;
    width: 100%;
    /* height: 100vh; */
    /* max-height: 100vh; */
    height: 627.2px;
    transition: transform .5s ease-out;
    -webkit-transition: transform .5s ease-out;
}

.CarouselIndicators {
    position: absolute;
    padding-left: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    top: 85%;
    right: 50vw;
}

@media screen and (max-height: 667px) {
    .CarouselIndicators {
        top: 90%;
    }
}
  
.CarouselIndicators li {
    display: inline-block;
    width: 45px;
    height: 5px;
    cursor: pointer;
    background-color: #A5A5A5;
    margin: 0 7.5px;
}

.CarouselIndicators li.active {
    background-color: #DDE295;
}

.SlideButtons {
    position: absolute;
    top: 50%;
    left: calc((100vw - 360px)/2);
    width: 360px;
    display: flex;
    justify-content: space-between;
}