.LoginContentContainer {
    position: relative;
    z-index: 2;
    height: 100vh;
}

.LoginContent {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    
}

.LoginContentBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: hsla(0,0%,99.2%,.65);
    max-width: calc(1320px*.9);
    width: 60%;
    padding: 5vh 3vw;
    margin: auto;
    box-shadow: 0 4px 4px rgba(0,0,0,.25);
    border-radius: 16px;
}

.LoginContentImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;
}

.LoginContentImage{
    width: 85%;
}

.LoginContentTitle {
    text-align: center;
    margin: 10px;
}

.LoginForm {
    display: grid;
} 

.LoginButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.DivisionLine {
    height: 250px;
    margin: 30px;
    width: 2px;
    background-color: #E1E2E1;
}

.LoginOtherOptions {
    display: grid;
    justify-items: center;

}

.LoginGoogleContainer {
    display: flex;
    justify-content: center;
    margin: 1rem;
}

@media only screen and (min-width: 320px) and (max-width: 1320px) {

    .LoginContentContainer {
        height: 110vh;
        height: 896px;
    }

    .LoginContentImage{
        width: 70%;
    }

    .SignIn {
        display: grid;
        justify-content: center;
    }

    .DivisionLine {
        display: none;
    }

    .LoginContentBox {
        display: grid;
        width: 80%;
        position: absolute;
        justify-content: center;
    }

    @media screen and (max-height: 667px) {
    
    }

}