.upgradeBookAccountContainer {
    background-color: #FDFEF6;
    height: calc(95vh - 90px);
    margin-top: 90px;
}

.upgradeBookAccountAboutTitle{
    text-align: center;
    font-size: 36px;
    padding: 10vh 0 2.5vh  0;
    font-family: 'Work Sans', sans-serif;
    margin: 0;
}

.upgradeBookAccountAboutText{
    text-align: center;
    font-size: 24px;
    /* padding: 1.5vh 0 1.5vh  0; */
    font-family: 'Work Sans', sans-serif;
}

.options{
    max-width: 1140px;
    display: flex;
    justify-content: center;
    margin: auto;
    height: 115px;
    align-items: baseline;
}

@media screen and (max-width: 420px){
    .upgradeBookAccountContainer {
        background-color: #FDFEF6;
        height: calc(100vh - 150px);
        margin-top: 70px;
    }

    .upgradeBookAccountAboutTitle{

        font-size: 26px;
    
    }
    .options{
        width: 90vw;
        display: flex;
        justify-content: space-around;
        margin: auto;
        align-items: baseline;
    }

    .upgradeBookAccountAboutText{
        font-size: 15px; 
    }
}