.hero {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

.introContent {
    display: flex;
}

.title{
    text-align: center;
}

.IntroImage {
    /* width: 90%; */
    /* height: 50vh; */
    height: 435px;
    object-fit: contain;
}

.bookSection {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FDFEF6;
}

.bookSectionContent {
    max-width: 1320px;
    height: 100%;
    display: grid;
    width: 100%;
}

.bookContent {
    display: flex;
}

.bookFeature {
    width: 33%;
    position: relative;
    display: grid;
}

.bookFeatureContent{
    display: flex;
    align-items: center;
    position: absolute;
}

.bookFeatureContent2 {
    display: flex;
    align-items: center;
    justify-content: end;
}

.bookFeatureText{
    width: 70%;
}

.bookFeatureLogo {
    margin: 0 25px;
}

.bookFeatureCallToAction {
    display: flex;
    justify-content: center;
    align-items: center;
}

.authorSection {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(#F6F9E5, rgba(179,224,217,0.6));
}

.authorSectionContent {
    display: flex;
    position: relative;
    height: 100%;
    max-width: 1370px;
}

.authorSectionText {
    width: 50%;
    margin: auto;
}

.authorSectionTextContent {
    width: 50%;
}

.authorSectionTextTitle {
    text-align: center;
    margin: 0;
    padding-bottom: 25px;
}

.authorSectionTextCallToAction {
    padding-top: 5px;
}

.authorSectionImageContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
}

.authorSectionImage {
    height: 60vh;
}

.bookUserSection {
    height: 120vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bookUserSectionContent {
    z-index: 2;
    display: grid;
    justify-items: center;
}

.bookImage{
    height: 60vh;
    object-fit: contain;
}

.bookUserImage {
    height: auto;
    width: 350px;
    height: 50vh;
    object-fit: contain;
    margin-top: 20px;
}

/* .textNumberOfTerms {

}

.textNumberOfTerms p {
    margin: 0;
} */

@media only screen and (min-width: 320px) and (max-width: 991px) {
    .hero {
        /* height: 150vh; */
        /* height: 1254px; */
        height: calc(100vh + 550px);
        align-items: start;
        /* padding-top: 10vh; */
        padding-top: 90px;
        position: relative;
      
    }

    .heroText {
        position: absolute;
        background-color: #fdfef7;
        /* height: 50vh; */
        height: 550px;
        left: 0;
        bottom:0;
    }

    .heroTextContent {
        width: 70%;
        margin: auto;
        display: grid;
        height: 100%;
        align-content: center;
    } 

    .heroTextContent a {
        width: 50%;
        margin: auto;
    }

    .bookImage{
        /* height: 40vh; */
        height: 358px;
    }

    .heroTextContentTitle {
        text-align: center;
        /* padding-bottom: 7vh; */
    }

    .heroCallToAction {
        padding-top: 25px;
    }
    .bookFeature {
        display: none;
    }

    .bookContent {
        justify-content: center;
    }

    .bookUserSection {
        /* height: 160vh; */
        height: 1434px;
   
    }

    .authorSection {
        /* height: 120vh; */
        height: 1075px;
       
    }

    .authorSectionText {
        width: 100%;
        margin: unset;
    }

    .authorSectionTextContent {
        width: 70%;
        margin: auto;
        margin-top: 10vh;
    }

    .authorSectionImage {
        /* height: 50vh; */
        height: 448px;
    }

    .authorSectionImageContainer {
        position: absolute;
        bottom: 0;
        right: calc((100vw - 262.2px)/6);
        display: flex;
    }

    .bookUserText {
        width: 70%;
    }


    .bookSection {
        /* height: 70vh; */
        height: 627px;
   
    }

    .bookSectionContent {
        align-items: flex-start;
    }

    /* @media screen and (max-height: 667px) {

        .authorSection {
            height: 140vh;
           
        }
        .heroCallToAction {
            padding-top: 3vh;
        }

        .bookUserSection {
            height: 200vh;
       
        }

    } */

   
}