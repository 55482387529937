.container {
    margin-top: 10vh;
    height: calc(100vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    display: grid;
    justify-items: center;
}