.upgradeAccountContainer {
    background-color: #FDFEF6;
    height: calc(95vh - 90px);
    margin-top: 90px;
}

.accountOptions {
    display: flex;
    width: 1140px;
    justify-content: space-around;
    margin: auto;
}

.upgradeAccountTitle {
    text-align: center;
    font-size: 36px;
    /* padding: 10vh 0 2.5vh 0; */
    font-family: 'Work Sans', sans-serif;
    margin:0
}

.upgradeAccountText {
    text-align: center;
    /* padding-bottom: 10vh; */
    margin:0;
    font-family: 'Work Sans', sans-serif;
    color: #004F3D;
}

.accountOption{
    width: 325px;
    height: 255px;
    text-align: center;
    display: grid;
    align-content: space-evenly;
    border-radius: 7px;
}


.gridContainer {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: auto auto auto auto;
    background-color: #f6f9e5;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 4px 4px rgba(0,0,0,.25);
}
.gridItem {
    background-color: #f6f9e5;
    /* border: 1px #A5A5A5;
    border-style: none solid solid solid; */
    /* padding: 5px; */
    font-size: 15px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    width: unset;

}

.currentSubscription {
    background-color: #E4E8CB;
    display: flex;
    height: 100%;
    width: 90%;
    margin: auto;

}

.gridItem span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.gridItem ul {
    display: flex;
    list-style-type: none;
    padding: 0;
}

.gridItem:nth-child(6) {
    border: 2px solid #A5A5A5;
}

.gridItem:nth-child(10), 
.gridItem:nth-child(14), 
.gridItem:nth-child(18),
.gridItem:nth-child(22), 
.gridItem:nth-child(26), 
.gridItem:nth-child(30),
.gridItem:nth-child(34) {
    border: 2px #A5A5A5;
    border-style: none solid solid solid;
}

.gridItem:nth-child(38) {
    border: 2px #A5A5A5;
    border-style: none solid none solid;
}

.gridItem:nth-child(7) {
    border: 2px #A5A5A5;
    border-style: solid solid solid none;
}

.gridItem:nth-child(11), 
.gridItem:nth-child(15),
.gridItem:nth-child(19), 
.gridItem:nth-child(23), 
.gridItem:nth-child(27),
.gridItem:nth-child(31), 
.gridItem:nth-child(35){
    border: 2px #A5A5A5;
    border-style: none solid solid none;
} 

.gridItem:nth-child(39){
    border: 2px #A5A5A5;
    border-style: none solid none none;
}

.gridItem:nth-child(8) {
    border: 2px #A5A5A5;
    border-style: solid none solid none;
}

.gridItem:nth-child(12), 
.gridItem:nth-child(16),
.gridItem:nth-child(20), 
.gridItem:nth-child(24), 
.gridItem:nth-child(28),
.gridItem:nth-child(32), 
.gridItem:nth-child(36) {
    border: 2px #A5A5A5;
    border-style: none none solid none;
} 

.gridItem:nth-child(5) {
    border: 2px #A5A5A5;
    border-style: solid none solid none;
}

.gridItem:nth-child(9), 
.gridItem:nth-child(13),
.gridItem:nth-child(17), 
.gridItem:nth-child(21), 
.gridItem:nth-child(25),
.gridItem:nth-child(29), 
.gridItem:nth-child(33) {
    border: 2px #A5A5A5;
    border-style: none none solid none;
} 


.gridItem:nth-child(2) {
    border: 2px #A5A5A5;
    border-style: none solid none solid;
}

.gridItem:nth-child(3) {
    border: 2px #A5A5A5;
    border-style: none solid none none;
}

.gridItem:nth-child(1) {
    color: #068B77;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.gridItem:nth-child(2) {
    background-color: #E0F2F0;
    color: #068B77;
}

.gridItem:nth-child(3) {
    background-color: #068B77;
    color: #E0F2F0;
}

.gridItem:nth-child(4) {
    background-color: #004F3D;
    color: #E0F2F0;
}

.gridItem:nth-child(2),
.gridItem:nth-child(3), 
.gridItem:nth-child(4) {
    display: grid;
    justify-content: center;
    height: 75px;
    font-size: 16px;
    align-content: center;
    justify-items: center;
} 

.gridItem:nth-child(42) span, .gridItem:nth-child(43) span, .gridItem:nth-child(44) span {
    color: #004F3D;
    font-weight: 500;
    height: 100%;
    display: flex;
    justify-content: center;
    font-size: 12px;
}


@media screen and (max-width: 768px){
    .upgradeAccountContainer {
        height: calc(100vh - 150px);
        margin-top: 70px;
    }

    .upgradeAccountTitle {
        padding: 5vh 0 2.5vh 0;
    }

    .accountOptions {
        display: grid;
        width: 100vw;
        padding-bottom: 15vh;
    }

    .gridItem {
        background-color: #f6f9e5;
        /* border: 1px #A5A5A5;
        border-style: none solid solid solid; */
        /* padding: 5px; */
        font-size: 13px;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        width: 20vw;
    
    }
    .accountOption{
        margin-bottom: 5vh;
    }

    .gridContainer {
       width: 90vw;
    }

    .upgradeBtn {
        width: unset !important;
        font-size: 8px !important;
    }
}