.appFeatures {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.appFeaturesContent {
    position: absolute;
}

.appFeaturesTitle {
    text-align: center;
}

.appFeaturesFeatures {
    display: flex;
    max-width: 1320px;
}

.appFeaturesThirdFeatureContainer {
    display: grid;
    align-content: space-evenly;
}

.appFeaturesSecond{
    display: grid;
    align-content: space-between;
} 

.appFeatureCol1, .appFeatureCol2 {
    display: flex;
    align-items: center;
}

.appFeatureCol2 {
    flex-direction: row-reverse;
}


.appFeaturesLogo {
    margin: 0 25px;
}

.appFeaturesImage {
    width: 400px;
    height: 50vh;
    object-fit: contain;
}

.CreateAccount {
    height: 850px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CreateAccountContent {
    max-width: 1320px;
    width: 100%;
    position: absolute;
}

.CreateAccountTitle {
    text-align: center;
}

.CreateAccountAccounts {
    display: flex;
    justify-content: space-evenly;
}

.CreateAccountAccount {
    width: 40%;
}

.CreateAccountAccountContent {
    text-align: center;
    background: #F6F9E5;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 8px;   
    width: 80%;
    margin: auto;
    padding: 2rem;
}

.CreateAccountAccountContent li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.CreateAccountAccountContent ul {
    margin: 0;
    padding: 0;
}

.CreateAccountAccountTitle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.CreateAccountBenefits {
    padding-top: 3vh;
}

.CreateAccountBenefitsDescription {
    padding-top: 3vh;
    text-align: left;
    display: flex;
    justify-content: center;
}

.CreateAccountCallToAction {
    display: flex;
    justify-content: center;
}

.AddToHomescreenContainer {
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f6;
}

.AddToHomescreen {
    max-width: 1320px;
}

.AddToHomescreenContent {
    display: flex;
    justify-content: center;
}

.AddToHomescreenText {
    width: 50%;
}

.AddToHomescreenImage {
    height: auto;
    width: 350px;
    height: 50vh;
    object-fit: contain; 
}

.OSIntructions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.BookAccountPWAContainer {
    height: 1200px;
    background-color: #F6F9E5;
    display: grid;
    align-content: end;
}

.BookAccountPWA {
    display: grid;
    /* height: 120vh; */
    align-content: space-between;
}

.OSText {
    font-family: 'Roboto';
    padding: 5px;
}

.OSText ol{
    padding-left: 25px;
}

.OSText p {
    color: #068B77;
    font-size: 24px;
}

.OSText a {
    color: #068B77;
    text-decoration: underline;
}

.BookAccountPWAIntro {
    text-align: center;
    /* height: 75vh; */
}

.appInstructions {
    display: flex;
    justify-content: center;
}

.BookAccountPWAIntroTitle {
    margin: 0;
}

.BookAccountPWAIntroTitleSkeleton {
    width: 750px;
    height: 48px;
    margin: 16px;
}

.BookAccountPWAIntroText {
    font-size: 20px;
    display: grid;
    justify-items: center;
    max-width: 900px;
    margin: auto;
    text-align: justify;
}

.BookAccountPWAIntroTextSkeleton {
    width: 750px;
    height: 48px;
    margin: 16px;
}

/* .BookAccountPWAIntroText p {
    width: 50%;
    text-align: center;
    margin: 10px;
} */

.BookAccountPWASteps, .BookAccountPWACallToAction {
    display: grid;
    justify-content: center;
    counter-reset: section;
    max-width: 600px;

}

.BookAccountPWAStepsSkeleton {
    width: 500px;
    height: 48px;
    margin: 16px;
}

.BookAccountPWASteps ol {
    text-align: start;
}

.BookAccountPWASteps li {
    font-family: 'Work Sans';
    font-size: 1.3rem;
    font-weight: 500;
    color: #4FB8A8;
    padding: 5px;
    margin-right: 10px;
    list-style-type: none;
    list-style-position: outside;
    
} 



.BookAccountPWASteps li::before {
    counter-increment: section;
    content: counter(section);
    border: 1px #4FB8A8 solid;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-right: 10px;
   
}

.BookAccountPWADescription {
    margin: auto;
    padding-top: 3vh;
    font-size: 24px;
}
.BookAccountPWAImageContainer {
  display: flex;
  justify-content: center;
  align-items: end;
}
.BookAccountPWAImage {
    height: 40vh;
    object-fit: cover;
    object-position: 0 0;
    width: 400px;
}

.gridContainer {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: auto auto auto auto;
    background-color: #f6f9e5;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 4px 4px rgba(0,0,0,.25);
}
.gridItem {
    background-color: #f6f9e5;
    font-size: 15px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    height: 52.5px;
}

.FeatureSkeleton {
    width: 400px;
    height: 30px !important;
}

.AccountSkeleton {
    width: 100px;
    height: 30px !important;
}

.FeatureListSkeleton {
    width: 200px;
    height: 30px !important;
}

.currentSubscription {
    background-color: #E4E8CB;
    display: flex;
    height: 100%;
    width: 90%;
    margin: auto;

}

.gridItem span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.gridItem ul {
    display: flex;
}

.gridItem:nth-child(6) {
    border: 2px solid #A5A5A5;
}

.gridItem:nth-child(10), 
.gridItem:nth-child(14), 
.gridItem:nth-child(18),
.gridItem:nth-child(22), 
.gridItem:nth-child(26), 
.gridItem:nth-child(30),
.gridItem:nth-child(34) {
    border: 2px #A5A5A5;
    border-style: none solid solid solid;
}

.gridItem:nth-child(38) {
    border: 2px #A5A5A5;
    border-style: none solid none solid;
}

.gridItem:nth-child(7) {
    border: 2px #A5A5A5;
    border-style: solid solid solid none;
}

.gridItem:nth-child(11), 
.gridItem:nth-child(15),
.gridItem:nth-child(19), 
.gridItem:nth-child(23), 
.gridItem:nth-child(27),
.gridItem:nth-child(31), 
.gridItem:nth-child(35){
    border: 2px #A5A5A5;
    border-style: none solid solid none;
} 

.gridItem:nth-child(39){
    border: 2px #A5A5A5;
    border-style: none solid none none;
}

.gridItem:nth-child(8) {
    border: 2px #A5A5A5;
    border-style: solid none solid none;
}

.gridItem:nth-child(12), 
.gridItem:nth-child(16),
.gridItem:nth-child(20), 
.gridItem:nth-child(24), 
.gridItem:nth-child(28),
.gridItem:nth-child(32), 
.gridItem:nth-child(36) {
    border: 2px #A5A5A5;
    border-style: none none solid none;
} 

.gridItem:nth-child(5) {
    border: 2px #A5A5A5;
    border-style: solid none solid none;
}

.gridItem:nth-child(9), 
.gridItem:nth-child(13),
.gridItem:nth-child(17), 
.gridItem:nth-child(21), 
.gridItem:nth-child(25),
.gridItem:nth-child(29), 
.gridItem:nth-child(33) {
    border: 2px #A5A5A5;
    border-style: none none solid none;
} 


.gridItem:nth-child(2) {
    border: 2px #A5A5A5;
    border-style: none solid none solid;
}

.gridItem:nth-child(3) {
    border: 2px #A5A5A5;
    border-style: none solid none none;
}

.gridItem:nth-child(1) {
    color: #068B77;
    font-size: 28px;
}

.gridItem:nth-child(2) {
    background-color: #E0F2F0;
    color: #068B77;
}

.gridItem:nth-child(3) {
    background-color: #068B77;
    color: #E0F2F0;
}

.gridItem:nth-child(4) {
    background-color: #004F3D;
    color: #E0F2F0;
}

.gridItem:nth-child(2),
.gridItem:nth-child(3), 
.gridItem:nth-child(4) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 62.5px;
    font-size: 16px;
    /* width: 212px; */
} 

.gridItem:nth-child(42) , .gridItem:nth-child(43) , .gridItem:nth-child(44)  {
    color: #004F3D;
    font-weight: 500;
    height: 100%;
    display: flex;
    justify-content: center;
    font-size: 12px;
}

.openDict {
    /* display: block !important; */
}

.openDict p {
    margin: 0;
}

.gridItem ul {
    padding: 0 0 0 0;
    list-style-type: none;
}


@media only screen and (min-width: 320px) and (max-width: 576px) {

    .appFeatures {
        /* height: 170vh; */
        height: 1650px;
    }

    .appFeaturesContent {
        position: absolute;
        top: 10vh;
        /* height: 100%; */
    }

    .appFeaturesFeatures {
        display: grid;
    }

    .appFeatureCol1:nth-child(2) {
        flex-direction: row-reverse;
    }

    .appFeatureCol2:nth-child(2) {
        flex-direction: row;
    }

    .appFeaturesLogo {
        margin: 0 5px;
    }
    .appFeaturesFirst {
        order: 0;
        width: 90%;
        margin: auto;
        padding-bottom: 5vh;
    }
    .appFeaturesSecond{
        order: 1;
        width: 90%;
        margin: auto;
    } 
    .appFeaturesThirdFeatureContainer {
        order: 2;
        width: 90%;
        margin: auto;
    }
    .appFeaturesSecondFeature {
        margin-top: 0vh;
        margin: 5vh 0;
        flex-direction: row-reverse;
    }

    .appFeaturesThirdFeature {
        flex-direction: row-reverse;
    }

    .appFeaturesImage {
        display: flex;
        width: 100%;
    }

    .appFeaturesFirstFeature, .appFeaturesThirdFeature,  .appFeaturesSecondFeature{
        align-items: center;
    }
 
    .CreateAccountAccount {
        width: 90%;
    }

    .CreateAccount {
        /* height: 165vh; */
        height: 1500px;
 
    }

    .BookAccountPWASteps li {
        font-size: 1.1rem;
    }

    .gridContainer {
       width: 90vw;
    }

    .gridItem {
        height: unset;
        font-size: 12px;
        color: #292929;
        width: 20vw;
    }

    .gridItem ul {
        padding: 0 0 0 0;
        list-style-type: none;
    }

    .gridItem a {
        font-size: xx-small;
        padding: 0;
    }

    .gridItem:nth-child(2),
    .gridItem:nth-child(3), 
    .gridItem:nth-child(4) {
        font-size: 15px;
        display: grid;
        height: 85px;
        justify-items: center;
    } 

    .gridItem:nth-child(1) {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .FeatureSkeleton {
        width: 30px;
        height: 30px !important;
    }
    
    .AccountSkeleton {
        width: 30px;
        height: 30px !important;
    }
    
    .FeatureListSkeleton {
        width: 30px;
        height: 100px !important;
    }

    .CreateAccountAccountContent {
        width: 80vw;
        margin: 3vh 0;
    }
    .AddToHomescreenContainer {
        /* height: 140vh; */
        height: 1400px;
        
    }

    .AddToHomescreen {
        height: 100%;
    }

    .AddToHomescreen p {
        width: 90vw;
        margin: auto;
    }

    .AddToHomescreenContent {
        display: grid;
        height: 100%;
        justify-items: center;
        align-content: space-evenly;
    }

    .OSIntructions {
        display: grid;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }


    .BookAccountPWAContainer {
        /* height: 200vh;*/
        height: 1500px;
        align-content: space-between;
    }

    .BookAccountPWA {
        /* height: 200vh; */
        height: 1500px;
        /* align-content: end; */
    }
    .BookAccountPWAIntro {
        margin-bottom: 10vh;
        margin-top: 5vh;
    }

    .BookAccountPWAIntroTitle {
        width: 95vw;
        margin: auto;

    }

    .BookAccountPWAIntroTitleSkeleton {
        width: 90vw;
        height: 48px;
        margin: 16px;
    }

    .BookAccountPWAIntroText {
        margin: 0;
        font-size: 14px;
        width: 100vw;
    }

    .BookAccountPWAIntroTextSkeleton {
        width: 90vw;
        height: 48px;
        margin: 16px;
    }

    .BookAccountPWAIntroText p {
        width: 90vw;
    }

    .BookAccountPWASteps {
        margin-bottom: 5vh;
        justify-items: baseline;
    }

    .BookAccountPWASteps ol {
        padding-left: 20px;
    }

    .BookAccountPWAStepsSkeleton {
        width: 90vw;
        height: 48px;
        margin: 16px;
    }

    .BookAccountPWADescription {
        font-size: 16px;
        margin: 0;
    }

    .BookAccountPWACallToAction {
        margin-bottom: 5vh;
    }

    .BookAccountPWAImage {
        width: 300px;
    }

    .appInstructions {
        /* height: 100vh; */
        height: calc(1500px - 900px - 15vh);
        display: grid;
        justify-content: center;
        width: 100vw;
        align-content: end;
    }

    /* @media screen and (max-height: 667px) {

        .appFeatures {
            height: 180vh;
        }

        .CreateAccount {
            height: 190vh;
     
        }

        .AddToHomescreenContainer {
            height: 160vh;
            
        }
        .BookAccountPWAContainer {
            height: 220vh;    
        }
    
        .BookAccountPWA {
            height: 220vh;
            align-content: space-between

        }

    } */


}