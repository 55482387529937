.imprintContainer{
    margin-top: 10vh;
    background-color: #E0F2F0;
}

.backgroundImage {
    position: absolute;
    opacity: 0.1;
}

.imprint {
    max-width: 1200px;
    margin: auto;
    padding: 5vh 0 10vh 0;
    position: relative;
    font-family: 'Roboto', sans-serif;
}

.imprintTitle {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 10vh;
    font-family: 'Work Sans', sans-serif;
}

.imprintItem {
    display: flex;
    padding: 1vh 0;
}

.imprintItem__text {
    margin-left: 25px;
}

.imprintItem__textTitle {
    font-size: 1rem;
    font-weight: 700;
}

@media screen and (max-width: 480px){
    .imprint {
        padding: 5vh 10vw;
    }

    .imprintItem {
        display: grid;
        justify-items: baseline;
    }
}