.header {
    /* min-height: calc(100% + env(safe-area-inset-top)); */
    height: 90px;
    /* min-height: 75px; */
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    background-color: #068b77;
    box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
    display: flex;
    justify-content: center;
    width: 100vw;
    position: fixed;
    z-index: 2;
}

.navbar {
    display: flex;
    justify-content: space-evenly;
    /* background-color: #068b77; */
    height: inherit;
    width: 90%;
    max-width: 1400px;
    /* min-height: 75px; */
    position: fixed;
    top: 0;
    z-index: 100;
    
}

.navbarLinksContainer {
    width: 400px;
    height: 100%;
}

.navbarLinks {
    display: flex;
    align-items: end;
    justify-content: space-evenly;
    height: 100%;
 
}

.navLink{
    font-family: 'Roboto', sans-serif;
    color: #FDFDFD;
    font-size: 1rem;
    border-bottom: 3px #068b77 solid;
    text-align: center;
}

.navLink span {
    font-size: 13px;
}

 
.navLink{
    padding-bottom: 0.5rem;
    font-weight: 600;
}


.search{
    width: 350px;
    display: flex;
    align-items: end;
}

.searchLanguage {
    display: flex;
    color: white;
    font-family: 'Roboto';
}

.ResponsiveNavbarContainer {
    display: none;
}

.openTerm {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-size: 7px;
    color: #004F3D;
    background-color: #DDE295;
    padding: 2px 6px;
    margin-left: 10px;
    border-radius: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: fit-content;
}


.languageOfSearchedTerm {
    background-color: #B3E0D9;
    color: #004F3D;
    width: 30px;
    height: 15px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-left: 10px;
}
  
  

@media screen and (max-width: 991px) {
    .header{
        height: calc(70px + env(safe-area-inset-top));
        /* padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left); */
        background-color: transparent;
        width: 100vw;
        position: fixed;
        top: 0;
        z-index: 200;
        display: block;
        
    }

    .navbarLinksContainer {
        display: none;
    }

    .search{
        width: 250px;
        display: flex;
        align-items: center;
    }

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        height: inherit;
        width: 100vw;
        position: fixed;
        top: 0;
        z-index: 100;
        box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
    
    }
    .navLink{
        padding: 0.8rem;
        font-size: 0.6rem;
        /* height: 11vh; */
        /* width: 11vh; */
        height: 90px;
        width: 90px;
        padding: 1vh;
        border: none;
    }

    .navbarLinks {
        width: 100vw;
        background-color: rgba(253, 253, 253, 1);
        /* box-shadow: 1px -2px 10px 0px rgb(0 0 0 / 25%); */
        filter: drop-shadow(0px -2px 2px #E1E2E1);
        height: 80px;
    }

    .navLink a span{
        display: grid;
       
        height: 20%;
        align-content: center;
        color: #4FB8A8 !important;
    }

    .active a span{
        display: grid;
       
        height: 20%;
        align-content: center;
        color: white !important;
    }

    .active  a{
        color: #FDFDFD;
        align-content: flex-start;
    }

    .navLink i{
        font-size: 2.3em;
        color: #4FB8A8;
    }

    .active div, .active i, .active span{
        color: #FDFDFD
    }

    .active{
        background: linear-gradient(180deg, #068B77 0%, #004F3D 100%);
        border-radius: 100%;
        border: solid .5em #FDFDFD;
        border-color: #FDFDFD;
        --bs-nav-tabs-border-width: 3px;
        --bs-nav-tabs-border-color: #FDFDFD;
        background-color: var(--bs-nav-tabs-link-active-bg);
        border-color: var(--bs-nav-tabs-link-active-border-color);
    }
    .navLink span {
        font-size: 11px;
    }

   .ResponsiveNavbarContainer {
    display: block;
   
}
    

}

@media screen and (max-width: 375px) {
    .header {
        min-height: 50px;
    }

    .navbar {
        min-height: 50px;
    }

    .navLink{
        font-size: 0.5rem;
        /* height: 12vh; */
        /* width: 12vh; */
        height: 90px;
        width: 90px;
        padding: 1vh;
        border: none;
    }

    .active{
        background: linear-gradient(180deg, #068B77 0%, #004F3D 100%);
        border-radius: 100%;
        border: solid .5em #FDFDFD;
        border-color: #FDFDFD;
        --bs-nav-tabs-border-width: 3px;
        --bs-nav-tabs-border-color: #FDFDFD;
        background-color: var(--bs-nav-tabs-link-active-bg);
        border-color: var(--bs-nav-tabs-link-active-border-color);

        
    }
    .navLink span {
        font-size: 11px;
    }
    
}

@media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    { 
        .header{
            height: calc(70px + env(safe-area-inset-top));
            width: 100vw;
            background-color: transparent;
            position: fixed;
            top: 0;
            z-index: 200;
        }
        /* .search{
            position: absolute;
            left: 10vw;
        } */
        .navbar {
            display: flex;
            justify-content: space-between;
            align-items:flex-end;
            background-color: transparent;
            height: inherit;
            width: 100vw;
            position: fixed;
            top: 0;
            z-index: 100;
            box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
        }
        .toggleSearchBar{
            margin-bottom: 1.5vh;
        }
        .navLink span {
            font-size: 11px;
        }

    } 