.ForgotPasswordContainer {
    height: 92vh;
    position: relative;
}

.ForgotPasswordBox {
    background: rgba(253, 253, 253, 0.65);
    width: 550px;
    height: 600px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    position: absolute;
    bottom: calc((100vh - 600px)/2);
    right: calc((100vw - 550px)/2);
}

.ForgotPasswordTitle {
    text-transform: uppercase;
    font-size: 1.8rem;
    text-align: center;
    padding-top: 30px;
}

@media only screen and (min-width: 320px) and (max-width: 576px) {

    .ForgotPasswordBox {
        width: 90vw;
        height: 60vh;
        bottom: calc((100vh - 60vh)/2);
        right: calc((100vw - 90vw)/2);
    }

    .ForgotPasswordTitle {
        padding-top: 2.5vh;
        font-size: 1.4rem;
    }

}