.RegistrationContainerContainer {
    height: 900px;
    position: relative;
    display: flex;
}

.RegistrationContainer {
    display: flex;
    height: 95%;
}

.RegistrationBox {
    position:absolute;
    right: calc((100% - 900px)/2);
    top: 150px;
    background: rgba(253, 253, 253, 0.65);
    width: 900px;
    /* max-width: calc(1320px*.9); */
    height: 700px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    /* display: flex; */
}

.Registration {
    display: grid;
    width: 50%;
    justify-content: center;
}

.RegistrationPWCheckItem {
    display: flex;
    align-items: center;
}

.RegistrationPWCheckItem p{
    margin: 0;
}

.RegistrationOtherOptions {
    display: grid;
    width: 50%;
    justify-content: center;
    align-content: center;
}

.DivisionLine {
    height: 80%;
    width: 2px;
    background-color: #E1E2E1;
    margin-top: 5%;
}


@media only screen and (min-width: 320px) and (max-width: 576px) {

    .RegistrationContainerContainer {
        /* height: 150vh; */
        height: 1120px;
       
    }

    .RegistrationContainer {
        display: grid;
    }

    .Registration {
        width: 100%;
    }

    .RegistrationOtherOptions {
       width: 100%;
    }

    .RegistrationBox {
        width: 90vw;
        right: calc((100% - 90vw)/2);
        /* height: 105vh; */
        height: 940px;
        top: 120px;
    }

    .DivisionLine {
        display: none;
    }

}

