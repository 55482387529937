.SendFeedbackContainer {
    height: calc(95vh - 90px);
    /* background-color: #FDFEF6; */
    display: flex;
    justify-content: space-evenly;
    align-items:flex-start;
    padding-top: 5vh;
    max-width: 1400px;
    margin: auto;
    margin-top: 90px;
}

.SendFeedback {
    /* width: 767px; */
    /* margin: auto; */
    /* padding-top: 10vh; */
    font-family: 'Roboto', sans-serif;
}

.SendFeedbackTitle {
    font-size: 24px;
    margin: 0;
}

.StarsRaiting {
    /* margin-bottom: 5vh; */
}

.sendButton {
    display: flex;
    justify-content: center;
    margin-top: 3vh;
}

.prevFeedback{
    width: 50%;
}

@media screen and (max-width: 476px) {
    .SendFeedbackContainer{
        display: grid;
        height: calc(100vh - 80px);
        padding: 50px 15px;
    }
    .SendFeedback {
        width: 80vw;
    }
    .SendFeedbackTitle {
        font-size: 26px;
        margin-bottom: 5vh;
        padding-top: 5vh;
    }
    .sendButton {
        display: flex;
        justify-content: center;
        margin-top: 3vh;
    }
    .prevFeedback{
        width: 100%;
    }
    
}