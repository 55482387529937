.footerContainer{
    height: 5vh;
    background-color:#004F3D;
    width: 100vw;
    position: fixed;
    bottom: 0;
    
}

.imprint{
    max-width: 1140px;
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    height: 5vh;
    align-items: center;
}

.imprintLink, .imprintCopyright {
    color: white;
    font-family: 'Roboto';
    margin: 0;
    font-weight: 600;
    font-size: 14px;
}