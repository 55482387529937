

.aboutContainer {
    padding: 1.5vh 0px;
    background: linear-gradient(180deg, rgba(224, 242, 240, 0.4) 0%, rgba(221, 226, 149, 0.4) 100%);
}

.about {
    max-width: 1140px;
    margin: auto;
}

.aboutContent {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.aboutWebpage{
    padding: 3vh 6vw;
    display: grid;
    justify-content: center;
}

.iconFooter{
    color: #B66A00;
    padding-right: 0.5rem;
    font-size: 1.5rem;
}

.footerLink{
    font-family: 'Roboto';
    color: #B66A00;
    font-weight: 500;
    font-size: 1rem;
    text-transform: uppercase;
}

.footerLink:hover {
    color: #886200;
}

.relatedInfoContainer {
    padding: 3vh 6vw;
}

.relatedSocialMedia {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1.5vh 0;
}

.relatedAdress {
    width: 100%;
    margin: auto;
    text-align: end;
    color: #004F3D;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 600;

}

.imprintContainer {
    background-color: #004F3D;
    height: 7vh;
}

.imprint{
    max-width: 1140px;
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    height: 7vh;
    align-items: center;
}

.imprintLink, .imprintCopyright {
    color: white;
    font-family: 'Roboto';
    margin: 0;
    font-weight: 600;
    font-size: 14px;
}


@media screen and (max-width: 476px) {
    footer{
        /* height: 60vh; */
        height: 538px;
    }

    .aboutWebpage{
        padding: 0;
    
    }

    .relatedSocialMedia {
        padding: 0;
    }

    .aboutContainer {
        /* height: 40vh; */
        height: 358px;
    }

    .aboutContent {
        flex-direction: column;
        /* height: 40vh; */
        height: 358px;
    }

    .relatedInfoContainer {
        padding: 0;
        /* height: 40vh; */
        display: grid;
        align-content: space-evenly;
    }

    .relatedAdress {
        text-align: center;
    }
    .imprintContainer {
        /* height: 20vh; */
        height: 179px;
        position: relative;
        bottom: 0px;
    }

    .imprint{   
        /* height: 20vh; */
        height: 179px;
        flex-direction: column;
    }

    @media screen and (max-height: 667px) {
        .footerLink{
            font-size: .8rem;
        }
        .aboutWebpage{
            padding: unset;
        }
        .relatedSocialMedia {
            padding:0;
        }
    }

  

  
} 