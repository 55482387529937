.darkBG {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100%;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 300;
    transform: translate(-50%, -50%);
}

.modal {
    font-family: 'Roboto';
    width: 750px;
    height: 500px;
    background: #E0F2F0;
    color: #292929;
    font-size: 14px;
    z-index: 30;
    border-radius: 10px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
    padding: 10px 25px;
    display: grid;
}