.BookIntro {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FDFEF6;
}

.BookIntroTitle {
    text-align: center;
}

.BookIntroImage {
    width: 400px;
    height: 55vh;
    object-fit: contain; 
    transform: rotateZ(-3deg);
}

.BookIntroCallToAction {
    margin-top: 5vh;
    display: flex;
    justify-content: center;
}

.BookDescription {
    height: 110vh;
    background-color: #FDFEF6;
}

.BookDescriptionContent {
    display: flex;
    max-width: 1320px;
    margin: auto;
    height: 100%;
    align-items: center;
}

.BookDescriptionText {
    width: 50%;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: #292929;
}

.BookInfo {
    width: 50%;
    display: flex;
    justify-content: center;
}

.BookInfoContainer {
    background: #FDFDFD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 3rem 1rem;
    display: flex;
    justify-content: space-around;
}

.BookInfoElement {
    display: grid;
    justify-items: center;
    margin: 0 25px;
}

.BookBuy {
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F6F9E5;
}

.BookBuyContent {
    position: absolute;
}

.BookBuyTitle {
    text-align: center;
}

.BookBuyText {
    text-align: center;
    font-size: 24px;
    margin-bottom: 25px;
}

.BookBuyEditorial {
    background: #FDFDFD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    width: 180px;
    height: auto;
    margin: auto;
    text-align: center;
}

.BookBuyEditorialImage {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
    .BookIntro {
        /* height: 120vh; */
        height: 896px;
    }

    .BookIntroImage {
        height: 448px;
    }
    .BookDescription {
        /* height: 120vh; */
        height: 1230px;
    }
    .BookBuy {
        /* height: 100vh; */
        height: 700px;
    }
    .BookDescriptionContent{
        display: grid;
    }

    .BookDescriptionText {
        width: 80%;
        margin: auto;
    }
    
    .BookInfo {
        width: 80%;
        margin: auto;
    }

    .BookInfoElement {
        margin: 0 10px;
    }

    .BookInfoContainer {
        padding: 1.5rem .5rem;
    }

    /* @media screen and (max-height: 667px) {
        .BookIntro {
            height: 120vh;
        }
        .BookDescription {
            height: 180vh;
        }
        .BookBuy {
            height: 120vh;
        }
    } */
}