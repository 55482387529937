.AuthorIntro {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #F6F9E5 0%, rgba(179, 224, 217, 0.6) 100%);
}

.AuthorIntro div {
    height: 100%;
    display: grid;
    align-content: space-between;
    justify-content: center;
}

.AuthorIntroTitle {
    text-align: center;
 
}
.AuthorIntroTitle h1 {
    margin-top: 20vh;
   
}

.AuthorIntroText {
    text-align: center;
    font-size: 24px;
    color: #068B77;
}

.AuthorIntroImageContainer {
    height: 50vh;
}

.AuthorIntroImage {
    height: 50vh;
    object-fit: contain;  
    /* position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%); */
}

.AuthorAbout {
    height: 850px;
    background-color: #F6F9E5;
    display: flex;
    position: relative;
}

.AuthorAboutContent {
    width: 60%;
    margin: auto;
    max-width: 1320px;
    display: grid;
    /* height: 80%; */
}

.AuthorAboutContent h3 {
    text-align: center;
}

.AuthorAboutCallToAction {
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
    .AuthorAbout {
        /* height: 190vh; */
        height: 1400px;

    }

    .AuthorIntroTitle h1 {
        margin-top: 15vh;
        padding:15px
    }

    .AuthorIntroImageContainer {
        height: 55vh;
        
    }

    .AuthorIntroImage {
        height: 55vh;
        top: 45%;
    }

    .AuthorAboutContent {
        width: 90%;
        height: 100%;
       
    }
}