html {
  min-height: calc(100% + env(safe-area-inset-top) + env(safe-area-inset-bottom));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  line-height: 1.5;
  width: 100vw;
  
}



body #root {
  overflow-x: hidden;
}



.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #DDE295;
  width: 32px;
  height: 32px;
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
}

#meaningText p, #meaningText li, #meaningTextOpen p, #meaningTextOpen li{
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif !important;
  
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* TEXTS */
h1{
  font-family: 'Work Sans', sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  color: #004F3D;
}

h2{
  font-family: 'Work Sans', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
}

h3{
  font-family: 'Work Sans', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  color: #004F3D;
}

.subtitle_bold{
  font-family: 'Work Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
}

.subtitle{
  font-family: 'Work Sans', sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
}

.body_text{
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 400;

  color: #292929;
}

.body_text_small{
  font-family: 'Roboto', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  color: #292929;
}

.body_text_small.snow{
  color: #FDFDFD;
}

/* ALERTS */

.no-danger{
  /* display: none; */
  opacity: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
}

.text-danger{
  font-family: 'Roboto', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  color: #F33757;
  /* display: block; */
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-left: 15px;
  width: 110%;
}
.is-invalid{
  border: 1px solid #F33757;
  box-shadow: 0px 0px 4px #F33757;
}

/* COLOR */
.primary_dark{
  color: #004F3D;
}
.snow{
  color: #FDFDFD;
}

/* COMPONENTS */

/* BUTTON */

button,
.btn{
  font-family: 'Roboto', sans-serif;
  font-size: 0.75rem;
  font-weight: 800;
  text-transform: uppercase;

  border-radius: 8px;

  padding: 8px 16px;
  width: 175px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.btn.size-auto{
  width: auto;
}

button.ochre,
.btn.ochre{
  background-color: #BD8F16;
  border: 1px solid #BD8F16;
  color: #FDFDFD;

  box-shadow: 0px 1.5px 2px rgba(121, 94, 32, 0.58);
}
button.ochre:hover,
.btn.ochre:hover{
  background: linear-gradient(180deg, #BD8F16 0%, #B66A00 100%);
}

button.snow,
.btn.snow{
  background-color: #FDFDFD;
  border: 1px solid #B66A00;
  color: #B66A00;
  
  box-shadow: 0px 1.5px 2px rgba(121, 94, 32, 0.58);
}
button.snow:hover,
.btn.snow:hover{
  background: #BD8F16;
  border: 1px solid #B66A00;

  color: #FDFDFD;
}

button.transparent,
.btn.transparent{
  border: 1px solid #FDFDFD;
  /*  */
  border-radius: 8px;

  background-color: transparent;
  color: #FDFDFD;
  
  box-shadow: 0px 1.5px 2px rgba(121, 94, 32, 0.58);
}
button.transparent:hover,
.btn.transparent:hover{
  filter: drop-shadow(0px 1.5px 2px rgba(121, 94, 32, 0.58));
  /* background: #BD8F16; */
  border: 1px solid #B66A00;

  color: #FDFDFD;
}
button.none {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  width: auto;
  font-family: 'Roboto';
}

/* ALERT */


.link{
  font-family: 'Roboto', sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #B66A00;
  text-decoration: underline;
}
.link:hover{
  color: #886200;
}

a{
  text-decoration: none;
  color: rgb(189, 143, 22);
}



#meaningText a {
  color: #068b77 !important;
  text-decoration: underline;
} 



/* tooltip right */

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  text-transform: none;
  font-weight: 500;
  white-space: nowrap;
  visibility: hidden;
  width: 175px;
  background-color: #F5F5F6;
  color: #292929;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 120%;
  font-size: 12px;
  margin: 0;
}
.tooltip .tooltiptext a {
  text-decoration: underline;
  color: #B66A00;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -9px;
  border-width: 9px;
  border-style: solid;
  border-color: transparent transparent transparent #F5F5F6;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* tooltip left */


.tooltipLeft {
  position: relative;
  display: inline-block;;
}

.tooltipLeft .tooltipLeftText {
  font-family: 'Roboto', sans-serif;
  visibility: hidden;
  width: 120px;
  background-color: #F5F5F6;
  color: #292929;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  position: absolute;
  z-index: 1;
  top: -9px;
  left: 110%;
  font-size: 12px;
}

.tooltipLeft .tooltipLeftText::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -9px;
  border-width: 9px;
  border-style: solid;
  border-color: transparent #F5F5F6 transparent transparent ;
}

.tooltipLeft:hover .tooltipLeftText {
  visibility: visible;
}


/* tooltip top */

.tooltipTop {
  position: relative;
  display: inline-block;

}

.tooltipTop .tooltipTopText {
  font-family: 'Roboto', sans-serif;
  visibility: hidden;
  width: 130px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 5px;
  background-color: #F5F5F6;
  color: #292929;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltipTop .tooltipTopText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #F5F5F6 transparent transparent transparent;
}

.tooltipTop:hover .tooltipTopText {
  visibility: visible;
}


button.link{
  padding: 0px;
  width: auto;
  height: auto;
  line-height: initial;
  background-color: transparent;
  border: none;
}

/* FIELD */
input{
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;

  background-color: #FDFDFD;

  border-radius: 8px;
  border: 1px solid #DDE295;

  padding: 8px 16px;
  width: 235px;
  height: 35px;

  color: #292929;
}

input:focus-visible {
  outline: #4FB8A8 solid 1px;
  /* box-shadow: 0px 0px 4px #B3E0D9; */
  border: 0px solid #E1E2E1;
}

input:hover {
  /* background-color: #E0F2F0; */
  border: 1px solid #DDE295;
}


.input-content{
  display: flex;
  align-items: baseline;
  height: 55px;
}

.nav-link.active{
  border-bottom: 6px #DDE295 solid;
}

/* TERMS */
.box_meaningTerm{
  background: #E0F2F0;
  height: 70vh;
  /* overflow-y: scroll; */
}



::-webkit-scrollbar {
  width: 3px;
}

/* scroll bar */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* FILTER */

.cont_filterButton.row{
  margin: 0px;
}

/* ICONS */
.icon{
  color: #B66A00;
  font-size: 1.8rem;
}

.icon-link{
  font-size: 1.2rem;
}

.icon-nav{
  color: #FDFDFD;
  font-size: 1.5rem;
}

.icon-socialM{
  color: #B66A00;
  padding-right: 0.5rem;
  font-size: 3rem;
}

.icon-confirm{
  color: #4FB8A8;
  font-size: 5rem;
}

.icon-aboutBook,.icon-benefits{
  color: #4FB8A8;
  font-size: 4rem;
}

.icon-account{
  color: #068B77;
  font-size: 1.5rem;
}

/* RESPONSIVE */
@media screen and (max-width: 1200px) {
  .container_term{
    width: 90vw;
    height: 68vh;

  }


  /* TEXTS */
  h1{
    font-size: 2.5rem;
  }

  h2{
    font-size: 2rem;
  }

  h3{
    font-size: 1.4rem;
  }

  .subtitle_bold{
    font-size: 1rem;
  }

  .subtitle{
    font-size: 1rem;
  }


  .body_text_small{
    font-size: 0.625rem;
  }

  .pre_title{
    font-size: 0.625rem;
  }

  /* COMPONENTS */
  /* BUTTON */
  button,
  .btn{
    /* width: auto; */
    height: 30px;
    /* margin: auto; */
  }
  button span, 
  button div{
    height: 30px;
  }

 
  /* ICON */
  .icon-nav{
    color: #B66A00;
  }

  .icon-confirm{
    font-size: 4rem;
  }
}


@media screen and (max-width: 480px){
  .containerThirdCarousel {
    height: 100vh;
  }

  .carousel-indicators2 {
    top: 85%;
    
  }
  .profile_image{
    height: 90px;
    width: 90px;
    border-radius: 100px;
    object-fit: cover;
  }


}


/* ANIMATIONS */

.reveal {
  position: relative;
  opacity: 0;
}
.reveal.active {
  opacity: 1;
}
.fade-center-top{
  animation: fade-bottom 1s ease-in;
}
.active.fade-bottom, .fade-bottom {
  animation: fade-bottom 1s ease-in;
}
.active.fade-left, .fade-left {
  animation: fade-left 1s ease-in;
}
.active.fade-right, .fade-right {
  animation: fade-right 1s ease-in;
}

@keyframes fade-center-top {
  0% {
    transform: translateY(200%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.carousel--scale .carousel__slide {
  -webkit-transform: scale(0);
          transform: scale(0);
}
.carousel__slide {
  /* height: 180px; */
  position: absolute;
  overflow-y: auto;
  /* opacity: 0; */
}

* {
  box-sizing: border-box;
}

.carouselContainer {
  display: flex;
  justify-content: center;
  height: 100vh;
}
.myCarousel {
  border-radius: 5px;
  align-self: center;
}
.carousel__slide {
  overflow: hidden;
}
.carousel--thumb .carousel__indicator {
  height: 30px;
  width: 30px;
}

.carousel__indicator {
  background-color: #fafafa;
}


.carousel__saleStep {
  width: 100%;
  height: 320px;
  display: flex;
  /* background-color: #FDFDFD; */
  color: white;
  border-radius: 15px;
  /* cursor: pointer; */
  padding: 7%;
}

.carousel__saleStep img {
  margin-right: 15px;
}

.carousel__saleStep p img {
width: 21px;
}

.saleStepText p {
  margin-top: 0;
  font-size: 13px;
  display: flex;
  align-items: flex-end;
  text-align: left;
}

