.notFoundPageContainer {
    position: relative;
    z-index: 3;
    height: 100vh;
}

.notFoundPage {
    display: grid;
    height: 100%;
    align-content: center;
    width: 900px;
    margin: auto;
}

.notFoundPageTitle {
    text-align: center;
}

.notFoundPageTitle h2{
    color: #004F3D;
}

.notFoundPageTitleContent {
    display: flex;
    align-items: center;
}

.notFoundPageImage {
    margin-right: 50px;
}

.notFoundPageTextAndCallToAction {
    display: grid;
    justify-items: center;
}

@media screen and (max-width: 420px) {
    .notFoundPageTitle h2{
        font-size: 1.5rem ;
    }
    .notFoundPage {
        width: 300px;
    }

    .notFoundPageTitleContent {
        display: grid;
        align-items: center;
    }

    .notFoundPageImage {
        margin: auto;
    }
}