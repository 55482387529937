.backgroundImage {
    position: absolute;
    opacity: 0.1;
}

.privacyNoticeContainer {
    margin-top: 10vh;
    background-color: #E0F2F0;
}

.privacyNotice {
    max-width: 1200px;
    margin: auto;
    padding: 5vh 0 10vh 0;
    position: relative;
    font-family: 'Roboto', sans-serif;
}

.title {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 10vh;
    font-family: 'Work Sans', sans-serif;
}

.subtitle {
    font-size: 1rem;
    font-weight: 700;
}

@media screen and (max-width: 480px){
    .privacyNotice {
        padding: 5vh 10vw;
    }
}