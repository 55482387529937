@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?29429520');
  src: url('../font/fontello.eot?29429520#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?29429520') format('woff2'),
       url('../font/fontello.woff?29429520') format('woff'),
       url('../font/fontello.ttf?29429520') format('truetype'),
       url('../font/fontello.svg?29429520#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?29429520#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-addwatchlist-c:before { content: '\e800'; } /* '' */
.icon-alert:before { content: '\e801'; } /* '' */
.icon-barcode:before { content: '\e802'; } /* '' */
.icon-comeback:before { content: '\e803'; } /* '' */
.icon-done:before { content: '\e804'; } /* '' */
.icon-email-2:before { content: '\e805'; } /* '' */
.icon-flag:before { content: '\e806'; } /* '' */
.icon-key:before { content: '\e807'; } /* '' */
.icon-language:before { content: '\e808'; } /* '' */
.icon-lock:before { content: '\e809'; } /* '' */
.icon-mobile:before { content: '\e80a'; } /* '' */
.icon-search:before { content: '\e80b'; } /* '' */
.icon-send:before { content: '\e80c'; } /* '' */
.icon-speaker:before { content: '\e80d'; } /* '' */
.icon-tablet:before { content: '\e80e'; } /* '' */
.icon-upgrade:before { content: '\e80f'; } /* '' */
.icon-user:before { content: '\e810'; } /* '' */
.icon-warning:before { content: '\e811'; } /* '' */
.icon-email:before { content: '\e813'; } /* '' */
.icon-facebook:before { content: '\e814'; } /* '' */
.icon-instagram:before { content: '\e815'; } /* '' */
.icon-twitter:before { content: '\e816'; } /* '' */
.icon-addwatchlist:before { content: '\e817'; } /* '' */
.icon-app:before { content: '\e818'; } /* '' */
.icon-arrow:before { content: '\e819'; } /* '' */
.icon-author:before { content: '\e81a'; } /* '' */
.icon-basic:before { content: '\e81b'; } /* '' */
.icon-book:before { content: '\e81c'; } /* '' */
.icon-close:before { content: '\e81d'; } /* '' */
.icon-conditions:before { content: '\e81e'; } /* '' */
.icon-copy:before { content: '\e81f'; } /* '' */
.icon-cover-password:before { content: '\e820'; } /* '' */
.icon-delete:before { content: '\e821'; } /* '' */
.icon-dictionary:before { content: '\e822'; } /* '' */
.icon-down:before { content: '\e823'; } /* '' */
.icon-edit:before { content: '\e824'; } /* '' */
.icon-email-1:before { content: '\e825'; } /* '' */
.icon-feedback:before { content: '\e826'; } /* '' */
.icon-goback:before { content: '\e827'; } /* '' */
.icon-home:before { content: '\e828'; } /* '' */
.icon-logout:before { content: '\e829'; } /* '' */
.icon-premium:before { content: '\e82b'; } /* '' */
.icon-privacy:before { content: '\e82c'; } /* '' */
.icon-profile:before { content: '\e82d'; } /* '' */
.icon-quitwatchlist:before { content: '\e82e'; } /* '' */
.icon-refresh:before { content: '\e82f'; } /* '' */
.icon-register:before { content: '\e830'; } /* '' */
.icon-search-1:before { content: '\e831'; } /* '' */
.icon-see-password:before { content: '\e832'; } /* '' */
.icon-seepicture:before { content: '\e833'; } /* '' */
.icon-share:before { content: '\e834'; } /* '' */
.icon-signin:before { content: '\e835'; } /* '' */
.icon-success:before { content: '\e836'; } /* '' */
.icon-takephoto:before { content: '\e837'; } /* '' */
.icon-up:before { content: '\e838'; } /* '' */
.icon-upload:before { content: '\e839'; } /* '' */
.icon-wachtlist:before { content: '\e83a'; } /* '' */
.icon-website:before { content: '\e83b'; } /* '' */
.icon-more:before { content: '\e83c'; } /* '' */
