.Container {
    width: 100vw;
    height: calc(100vh - 90px);
    display: flex;
    justify-content: center;
}

.Content {
    display: grid;
    justify-items: center;
    align-content: center;
}