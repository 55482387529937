.webHeader {
    height: 90px;
    padding: 0;
    background-color: #068b77;
    position: fixed;
    top: 0;
    z-index: 200;
}

.navbar {
    display: flex;
    justify-content: space-evenly;
    background-color: #068b77;
    height: inherit;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 100;
    box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
    /* padding-bottom: 5px; */
}

.navbarLinks {
    display: flex;
    align-items: end;

    justify-content: space-between;
    width: 900px;
}

.navbarButtons {
    padding-bottom: 0.5rem;
}

.navLink{
    padding-bottom: 5px;
    font-weight:normal;
    font-family: 'Roboto';
    border-bottom: 6px #068b77 solid;
}

.accountLinks {
    display: flex;
    width: 250px;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
}

.openDict p {
    margin: 0;
}





@media screen and (max-width: 1200px) {

    .navLink{
        border-bottom: none !important;
        font-size: 13px;
    }


    .webHeader {
        height: calc(70px + env(safe-area-inset-top));
        padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
        background-color: #068b77;
        position: fixed;
        top: 0;
        z-index: 200;
    }

    .logo{
        padding-top: env(safe-area-inset-top);
    }

    .navbar {
        display: flex;
        justify-content: flex-start;
        background-color: #068b77;
        height: inherit;
        width: 100vw;
        position: fixed;
        top: 0;
        z-index: 100;
        box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
    }

    .navbarLinks {
        position: absolute;
        display: block;
        background-color: #F5F5F6;
        top: calc(70px + env(safe-area-inset-top));
        color: #B66A00;
        height: 100vh;
      
        width: 240px;
        z-index: 50;
        box-shadow: 4px 0px 2px rgb(0 0 0 / 25%);
        padding: 50px 25px;
    }
 
    .hidden {
        transition: all .25s ease-out;
        left: -245px;
    }

    .visible {
        transition: all .25s ease-in;
        left: 0;
    }

    .burgerButton {
        width: 40px;
        height: 40px;
        border-radius: 0;
        background-color: #068b77;
        display: block;
        line-height: 40px;
        text-align: center;
        position: fixed;
        right: 5vw;
        top: calc(env(safe-area-inset-top) + 15px);
        z-index: 11;
        color: white;
        cursor: pointer;
    }

    .bar {
        width: 25px;
        height: 3px;
        background-color: #fff;
        margin: 6px 0;
      
        position: relative;
        top: 3px;
        left: 7px;
        border-radius: 3px;
    }

    .accountLinks {
        display: block;
        width: unset;
    }

    
    
}

@media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
     { 
        .webHeader {
            height: calc(70px + env(safe-area-inset-top));
            padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
            background-color: #068b77;
            position: fixed;
            top: 0;
            z-index: 200;
        }
        .navbar {
            display: flex;
            justify-content: flex-start;
            background-color: #068b77;
            height: inherit;
            width: 100vw;
            position: fixed;
            top: 0;
            z-index: 100;
            box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
        }
        .navbarLinks {
            position: absolute;
            display: block;
            background-color: #F5F5F6;
            top: calc(70px + env(safe-area-inset-top));
            color: #B66A00;
            height: 100vh;
          
            width: 240px;
            z-index: 50;
            box-shadow: 4px 0px 2px rgb(0 0 0 / 25%);
            padding: 50px 25px;
        }

        

    } 



    
