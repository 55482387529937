.termBox{
    padding: 1rem 0.5rem;
    border-bottom: 1px solid #E1E2E1;
    margin-right: 15px;
}

.termsContainer{
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(74vh - 120px);
    height: calc(95vh - 90px - 30px - 100px);
}

.termNotFound {
    width: 250px;
    color: #F33757;
    font-family: 'Roboto', sans-serif;
}

.termTitleAndActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.openTerm {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-size: 10px;
    color: #004F3D;
    background-color: #DDE295;
    padding: 3px 6px;
    margin-left: 10px;
    border-radius: 10px;
    font-weight: 600;
}

.termActionsContainer {
    display: flex;
}

.synonymTitle {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #292929;
}

.synonym {
    font-family: 'Roboto', sans-serif;
}


.termInLanguageContainer {
    display: flex;
}

.termLocale {
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: #292929;
}

.termText {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: #292929;
    max-width: 100%;
    word-wrap: break-word;
}



.loaderContainer {
    display: flex;
    justify-content: center;
    padding: 10px 0 60px 0;
}

.termsEnd {
    text-align: center;
    color: #B66A00;
    font-family:'Roboto', sans-serif;
}

@media screen and (max-width: 600px){
    .termsContainer{
        height: calc((100vh - 150px) - 90px);
    }
}