.container {
    height: calc(95vh - 90px);  
}

.content {
    max-width: 1400px;
    margin: auto;
    height: inherit;
    display: grid;
}

.backButton {
    position: absolute;
    transform: rotateY(180deg);
}

.newPwdFormContainer {
    height: calc(95vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.newPwdFormAndBackground {
    box-shadow: 0 4px 4px rgba(0,0,0,.25);
    background: hsla(0,0%,99.2%,.65);
    border-radius: 16px;
    width: 500px;
    display: flex;
    height: 600px;
    position: absolute;
}

.newPwdForm {
    display: grid;
    align-content: center;
    margin: auto;
}

.passCheck {
    display: flex;
    align-items: center;
    height: 30px 
}

.FormButtons {
    display: grid;
    height: 100px;
    justify-content: center;
    align-content: space-evenly;
    margin-top: 15px;
}


@media only screen and (min-width: 320px) and (max-width: 1320px) {
    .container {
        height: calc(100vh);  
        height: 896px;
        display: grid;
        align-content: center;
    }

    .backButton {
        top: 70px;
        left: 0;
    }

    .newPwdFormContainer {
        height: calc(100vh - 150px);  
    }

    .newPwdFormAndBackground {
        box-shadow: 0 4px 4px rgba(0,0,0,.25);
        background: hsla(0,0%,99.2%,.65);
        border-radius: 16px;
        width: 90vw;
        display: flex;
        height: 600px;
        position: absolute;
    }
}