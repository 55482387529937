.toast {
   display: block !important;
}

.notification-container {
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    font-family: 'Roboto';
}

.top-right {
    top: 125px;
    right: 12px;
    z-index: 100;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
    display: flex;
    padding: 10px;
}


