.ConfirmationContainer {
    height: 750px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Confirmation {
    display: grid;
    justify-items: center;
} 

.Confirmation p {
    text-align: center;
}
