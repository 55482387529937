.meaning {
    background: #E0F2F0;
    height: calc(85vh);
}

.meaningTermBoxNotSelected{
    background: #E0F2F0;
    height: calc(85vh - 30px);
    padding: 25px 35px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.meaningTermBoxNotSelected h1 {
    font-size: 2rem;
}

.meaningTermBox{
    background: #E0F2F0;
    height: calc(85vh - 30px);
    padding: 25px 15px;
    position: relative;
}

.meaningTermCallToAction {
    display: flex;
    justify-content: end;
    position: absolute;
    right: 30px;
    top: 5px;
    width: 100%;
}

.meaningTermBackButton {
    margin: 0;
}

.meaningTermShareButton {
    margin: 0;
}

.meaningTermShareButton i span{
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    font-style: normal;
    text-transform: lowercase;
    font-weight: 500;
}

.meaningTermBackButton i {
    transform: rotate(-180deg);
    color: #BD8F16;
}

.meaningTermTitle {
    text-align: center;
}

.meaningTermTitle h3 {
    margin: 0px 0;
}

.changeLanguageButtons {
    display: flex;
    background-color: #FDFDFD;
    width: 90%;
    justify-content: space-evenly;
    margin: auto;
    border-radius: 15px;
    height: 35px;
    align-items: center;
}

.changeLanguageButton {
    height: 25px;
    padding: 0 10px !important;
    border-radius: 15px;
}

.meaningTermContainer {
    padding: 10px 0;
    height: calc(85vh - 30px - 132px);
}

.meaningTermSkeleton {
    padding: 10px;
}

.meaningTermSubtitleAndSynoyms {
    color: #068B77;
    font-family: 'Work Sans', sans-serif;
    font-size: 1.3rem;
    font-weight: 700;
}

.meaningTermSubtitle {
    margin: 0px 0;
}

.meaningTermSynoyms {
    font-size: 16px !important;
    margin: 5px 0;
}

.meaningTermText {
    font-family: 'Roboto', sans-serif;
    font-size: 14px !important;
    height: 50vh;
    height: calc(100% - 61px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 15px;
    display: flex;
    padding: 10px 0;
    /* text-align: center; */
}

.willBeAvailableSoonContainer {
    width: 90%;
    height: 75%;
    background-color: #F6F9E5;
    align-self: center;
    margin: auto;
    display: grid;
    justify-items: center;
    text-align: center;
}

.willBeAvailableSoon {
    display: grid;
    align-content: space-evenly;
    justify-items: center;
    height: 90%;
}

.willBeAvailableSoon i{
    font-size: 4rem;
    color: #4FB8A8;
}

.willBeAvailableSoon span{
    color: #292929;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.pleaseRegisterContainer {
    position: relative;
}

.pleaseRegister {
    background-image: linear-gradient(rgba(224, 242, 240, .8), rgba(224, 242, 240, 1));
    position: relative;
    top: 0;
}

.pleaseRegisterContent {
    background-color: #F6F9E5;
    position: absolute;
    top: 50px;
    width: 80%;
    margin: auto;
    padding: 10%;
    text-align: center;
    right: 50px;
}

.pleaseRegisterContent i {
    color: #4FB8A8;
    font-size: 3.5rem;
}

.pleaseRegisterContent div {
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
}

.openDictionaryMeaning a{
    cursor: not-allowed;
    pointer-events: none;
    color: #292929 !important;
    text-decoration: underline !important;
}

.openDictionaryLinks {
    margin-top: 3px;
    font-size: 14px;
    font-family: 'Roboto';
    display: block;
    width: 100%;
    text-align: center;
}

.openDictionaryLinks a{
   text-transform: uppercase;
   color: #B66A00 !important;
   text-decoration: underline !important;
   font-weight: 500;
}


@media screen and (max-height: 900px){

    .meaningTermBox{
        background: #E0F2F0;
        height: calc(85vh - 70px);
        padding: 25px 15px;
        position: relative;
    }

    .meaningTermContainer {
        padding: 10px 0;
        height: calc(85vh - 70px - 132px);
    }

    .meaningTermTitle h3{
       font-size: 1.3rem;
       margin: 0;
    }

    .meaningTermSubtitleAndSynoyms {
        font-size: .8rem;
        margin: 0;
    }

    .meaningTermSynoyms {
        font-size: 12px !important;
        margin: 0;
    }

}

@media screen and (max-width: 600px){
    .meaningTermBox{
        background: #E0F2F0;
        height: calc(100vh - 180px);
        padding: 25px 15px;
        position: relative;
    }
}